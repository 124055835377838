
import { Options, Vue } from 'vue-class-component'
import InvoiceDetailPage from './InvoiceDetailPage.vue'
import axios from '@/plugins/axios'
import { ElMessage } from 'element-plus'
import { toRaw } from 'vue'
import braintree from 'braintree-web'
import dropin from 'braintree-web-drop-in';

import { UserType } from "@/constants/UserType"

interface Result {
  total: number
  list: []
  token: ''
}
interface Res {
  data: []
}
@Options({
  components: {
    InvoiceDetailPage
  },
  props: {
    listInvoices: [],
    ListCards: [],
  }
})
export default class InvoiceTablePage extends Vue {
  dialogInvoiceDetail = false
  dataItem = {}
  accountInfo = {}
  dataExport: any
  connection = {
    name: '',
    price: 0,
    quantity: 1
  }

  dataPdf = {
    total: 0,
    releaseDate: '',
    expireDate: '',
    idView: '',
    status: '',
    connections: [{}],
    items: [{}],
    type: '',
  }

  selectedInvoice: any = {}
  isShowPayNowPopup = false
  isShowDropIn = false
  braintreeToken = ''
  clientInstance: any
  dropinInstance: any = ''


  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }

  isSuperAdmin = false
  multipleSelection = []

  ListCards: any = []

  get cardInfo () {
    const defaultCard = this.ListCards.find((card: any) => card.default)
    if (defaultCard) {
      if (defaultCard.cardholderName) {
        return `${defaultCard.cardType} XXXX - ${ defaultCard.last4 }`
      } else {
        return 'Paypal ' + defaultCard.email
      }
    }
    return 'No default card available'
  }

  async created() {
    this.getAccounInfo()
    this.isSuperAdmin = await this.$store.state.auth.dataLogin.type === UserType.SuperAdmin
  }
  async getAccounInfo() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Res = {
      data: []
    }
    res = await axios.get('user/account/info', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account')
      }
    })
    if (res) {
      this.accountInfo = res
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  viewDetail(row: any) {
    if (row) {
      for (let item of row.connections) {
        if (item.from) {
          row.connections.splice(item, 1)
        }
      }
    }
    this.dataItem = row
    this.dialogInvoiceDetail = true
  }
  async exportFileCSV() {
    await this.$store.dispatch('setLoading', true, { root: true })

    let res: Result = await axios.get('user/invoice/all', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account')
      }
    })
    if (res) {
      this.dataExport = res.list
    }
    const listData = new Set([
      'idView',
      'packageName',
      'releaseDate',
      'expireDate',
      'total',
      'status'
    ])
    for (let obj of this.dataExport) {
      for (let prop of Object.keys(obj)) {
        if (!listData.has(prop)) {
          delete obj[prop]
        }
        if (typeof obj[prop] === 'object') {
          Object.assign(obj, { total: obj[prop].$numberDecimal })
        }
      }
    }

    await axios
      .post('/user/invoice/export', this.dataExport, { headers: this.headers })
      .then(function (response) {
        window.location = response.data.linkFileExport
        ElMessage.success('Export to csv successfully!')
      })
      .catch(function (error) {
        console.log(error)
        ElMessage.error(error)
      })

    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async clickDownload(item: any, type = 'pdf') {
    this.dataPdf.total = item.total
    this.dataPdf.releaseDate = item.releaseDate
    this.dataPdf.expireDate = item.expireDate
    this.dataPdf.idView = item.idView
    this.dataPdf.status = item.status


    if (item.type === 'custom') {
      this.dataPdf.items = item.items
      this.dataPdf.type = item.type
    } else {
      item.connections.forEach((i: any) => {
        this.connection.name = i.connection.name
        this.connection.price = i.price
        this.dataPdf.connections.push(this.connection)
        this.connection = {
          name: '',
          price: 0,
          quantity: 1
        }
      })
    }

    this.dataPdf.connections.splice(0, 1)
    this.dataPdf = Object.assign(this.dataPdf, this.accountInfo)
    await this.$store.dispatch('setLoading', true, { root: true })
    if (type == 'pdf') {
      await axios
        .post('/user/invoice/export-to-pdf', this.dataPdf, {
          headers: this.headers
        })
        .then(function (response) {
          window.location = response.data.linkFileExport
          ElMessage.success('Download successfully!')
        })
        .catch(function (error) {
          console.log(error)
          ElMessage.error(error)
        })
      this.dataPdf.connections = [{}]
      this.dataPdf.items = [{}]
    } else {
      let dataCsv = {
        Total: this.dataPdf.total,
        'Issue Date': this.dataPdf.releaseDate,
        // expireDate: this.dataPdf.expireDate,
        'ID View': this.dataPdf.idView,
        Status: this.dataPdf.status,
        'Package Name': this.dataPdf.connections
      }
      await axios
        .post('/user/invoice/export', dataCsv, {
          headers: this.headers
        })
        .then(function (response) {
          window.location = response?.data?.linkFileExport
          ElMessage.success('Download successfully!')
        })
        .catch(function (error) {
          console.log(error)
          ElMessage.error(error)
        })
      this.dataPdf.connections = [{}]
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  handleSelectionChange(val: any) {
    let arr: any = []
    for (let v of val) {
      v = toRaw(v)
      arr.push(Object.assign({}, v))
    }
    this.multipleSelection = arr
    this.$emit('seletedInvoices', arr)
  }

  showPayNowPopup(invoice: any) {
    this.selectedInvoice = invoice
    this.isShowPayNowPopup = true
  }

  async handleAuthorize() {
    await this.$store.dispatch('setLoading', true, { root: true })
    const invoicePayload = {
      invoiceId: this.selectedInvoice._id,
      accountId: localStorage.getItem('account'),
      nonce: '',
    }

    if (this.dropinInstance) {
      const payload = await new Promise<{ nonce: string }>((resolve, reject) => {
        this.dropinInstance.requestPaymentMethod((requestErr: any, payload: any) => {
          if (requestErr) {
            this.$message.warning(requestErr.message);
            return reject(requestErr);
          }
          resolve(payload);
        });
      });
  
      if (payload && payload.nonce) {
        invoicePayload.nonce = payload.nonce
      }

    }

    await axios
      .post('/user/invoice/authorize', invoicePayload, {
        headers: this.headers
      })
      .then((response: any) => {
        if (response.success) {
          ElMessage.success(response.message)
          this.$store.dispatch('reRender')
        } else {
          ElMessage.error(response.message)
        }
      })
      .catch(function (error) {
        console.log(error)
        ElMessage.error(error)
      })
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async showDropIn() {
    await this.$store.dispatch('setLoading', true, { root: true })

    this.isShowDropIn = true

    if (!this.braintreeToken) {
      await this.getBraintreeToken()
    }
  
    const dropinOptions: any = {
      authorization: this.braintreeToken,
      selector: '#dropin-container',
      paypal: {
        flow: 'vault',
      },
      card: {
        cardholderName: true,
        cardBrands: ['Visa', 'MasterCard'],
        vault: false,
      }
    }
  
    dropin.create(dropinOptions, (dropinError: any, dropinInstance: any) => {
      if (dropinError) {
        this.$message.warning('There was an error setting up the client instance. Message: ' + dropinError.message)
        return;
      }
      this.dropinInstance = dropinInstance;
    });

    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async getBraintreeToken() {
    let res: any = await axios.get("user/list-of-payment-card?type=token", {
      headers: this.headers
    })
    if (res) {
      this.braintreeToken = res.token
    }
  }

  cancelDropIn() {
    if (this.dropinInstance) {
      this.dropinInstance.teardown();
      this.isShowDropIn = false
      this.dropinInstance = false
    }
  }

  editInvoice(invoice: any) {
    return this.$emit("editInvoice", invoice);
  }
}


